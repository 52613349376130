import React from 'react';

import Layout from '../../../components/layout';
import withBg from '../../../helpers/withBg';
import SEO from '../../../components/seo';
import TrustpilotHorizontal from '../../../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../../../components/Sidebar/SidebarStaticPage';

const data = {
  title: 'Win a Trip | Terms & Conditions',
  seoTitle: 'Win a Trip | Terms & Conditions | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class WinTripTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Win a Trip | Terms &amp; Conditions</h1>
                <p>
                  <strong>NO PURCHASE NECESSARY.</strong>
                  <br />
                  <strong>
                    A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR
                  </strong>
                  <br />
                  <strong>CHANCES OF WINNING.</strong>
                </p>
                <p>
                  <strong>1. Eligibility:</strong> Travelopod 2023 Giveaway
                  (hereon referred to as the “Promotion”) is open only to legal
                  residents of the fifty (50) states of the United States of
                  America and the District of Columbia, who are at least
                  twenty-one (21) years old at the time of entering the
                  Promotion. The Promotion is subject to all applicable federal,
                  state, and local laws and regulations and void where
                  prohibited. Participation constitutes entrant’s full and
                  unconditional agreement to these Official Rules and Main
                  Sponsor’s and Administrator’s decisions, which are final and
                  binding in all matters related to the Promotion. Winning a
                  prize is contingent upon fulfilling all requirements set forth
                  herein.
                </p>
                <p>
                  <strong>2. Timing:</strong> The Promotion begins on April 1st,
                  2023 at 12:00 A.M. Pacific Time (“PT”) and ends on June 15th,
                  2023 at 11:59 P.M. Pacific Time (the “Promotion Period”).
                  Winner will be announced on June 20th, 2023. Administrator’s
                  computer is the official time-keeping device for this
                  Promotion.
                </p>
                <p>
                  <strong>3.</strong> How to Enter: Participant needs to visit
                  the Promotion’s Giveaway webpage and enter their details.
                  Participant will also have to enter their email address (for
                  communication purposes only). Once you sign up, you will
                  automatically register one (1) entry into the Giveaway. All
                  potential winners are subject to verification before any prize
                  is awarded. Administrator is responsible for the functionality
                  of the contest.
                </p>
                <p>
                  <strong>Limit: </strong> Each participant is eligible to make
                  up to one (1) contest entry. Multiple entrants are not
                  permitted to share the same email address. Any attempt by any
                  entrant to obtain more than the stated number of entries by
                  using multiple/different email addresses, identities,
                  registrations and logins, or any other methods will void the
                  entries and that entrant may be disqualified. Use of any
                  automated system to participate is prohibited and will result
                  in disqualification. Travelopod is not responsible for lost,
                  late, incomplete, invalid, unintelligible or misdirected
                  registrations, and any such entries will be disqualified. In
                  the event of a dispute as to any registration or play, the
                  authorized account holder of the email address used to
                  register will be deemed to be the registrant or player. The
                  “authorized account holder” is the natural person assigned to
                  an email address by an Internet access provider, online
                  service provider or other organization responsible for
                  assigning email addresses for the domain associated with the
                  submitted address. Potential winners may be required to show
                  proof of being the authorized account holder.
                </p>
                <p>
                  <strong>Airfare:</strong> Provided by Travelopod. Only one (1)
                  booking for a maximum of two (2) tickets, worth USD $1000.00.
                  Once your booking is confirmed, you will receive electronic
                  travel documents. These travel documents establish your
                  booking with a major domestic/international airline and allow
                  you to travel. The Passenger(s) will cover taxes. It is the
                  passengers’ responsibility to check that all the information
                  on the ticket is correct and to have all appropriate travel
                  documents and visas (if required). Once the tickets are
                  issued, they are non-refundable, non-transferable and no
                  modifications are permitted. At check in, passengers must
                  provide electronic ticket documents and proof of ID / Passport
                  with photo and all documents required for
                  domestic/international travel.
                </p>
                <p>
                  <strong>4. Contest Drawing:</strong> Administrator will
                  randomly select one (1) potential prize winner from all
                  eligible entries on June 20th, 2023.
                </p>
                <p>
                  <strong>5. Winners: </strong> All potential winners are
                  subject to verification by main sponsor or administrator,
                  whose decisions are final and binding in all matters related
                  to the promotion. An entrant is not a winner of any prize,
                  even if the Giveaway should so indicate, unless and until
                  entrant’s eligibility has been verified and entrant has been
                  notified that verification is complete. Main sponsor will not
                  accept screenshots or other evidence of winning in lieu of its
                  validation process. Any play that occurs after the system has
                  failed, for any reason, is deemed a defective play and is void
                  and will not be honored.
                </p>
                <p>
                  <strong>6. Verification of Potential Winners:</strong>{' '}
                  Potential winners must continue to comply with all the terms
                  and conditions of these Official Rules and any winning through
                  the Promotion is contingent upon fulfilling all requirements.
                  Each potential winner will be notified by email. If a
                  potential winner of any prize cannot be contacted within the
                  required time period (if applicable), or prize is returned as
                  undeliverable, such potential winner will be automatically
                  forfeiting the prize. In the event that the potential Prize
                  winner is disqualified for any reason, Travelopod will award
                  the Prize to an alternate winner by random drawing from among
                  all remaining eligible entries. All alternate potential
                  winners are subject to all requirements set forth in these
                  Official Rules.
                </p>
                <p>
                  <strong>7. Prizes:</strong> Giveaway Prize: One Prize of
                  Airfare for a maximum of two (2) tickets, worth USD $1000.00
                  to a destination as provided by Travelopod (on a
                  domestic/international airlines). Approximate Retail Value
                  (“ARV”) of the Prize is USD $1000.00 total excluding taxes and
                  fee. The maximum value covered is USD $1000.00, as applicable
                  to base fare. Anything over and above will be paid by the
                  winner. Actual value may vary based on availability. Winner
                  will not receive the difference between actual and approximate
                  retail value. Odds of winning the Giveaway depends upon the
                  number of eligible entries received during the Promotion
                  Period. Winner is solely responsible for their own conduct
                  while accepting the prize and agrees to comply with all
                  applicable laws and regulations pertaining to use and
                  enjoyment of the prize. All tickets must be issued on or after
                  June 21st, 2023 and travel completed by December 31st, 2023.
                  Main Sponsor will not replace any lost coupon codes. For All
                  Prizes: No cash equivalent and all prizes are non-transferable
                  and no substitution will be made except as provided herein at
                  the Main Sponsor’s sole discretion. Main Sponsor reserves the
                  right to substitute a prize for one of equal or greater value
                  if the designated prize should become unavailable for any
                  reason. Winner is responsible for all taxes and fees
                  associated with prize receipt and/or use. TOTAL ARV OF ALL
                  PRIZES IN THE GIVEAWAY: USD $1000.00
                </p>
                <p>
                  <strong>8. Release:</strong> By receipt of any prize, winner
                  agrees to release and hold harmless Travelopod and their
                  respective parent, subsidiary, and affiliate companies,
                  suppliers, distributors, and advertising/promotion agencies,
                  as well as each such company’s officers, directors, employees
                  and agents (collectively, the “Released Parties”) from and
                  against any claim or cause of action, including, but not
                  limited to, personal injury, death, or damage to or loss of
                  property, arising out of participation in the Promotion or
                  receipt, use, or misuse of any prize.
                </p>
                <p>
                  <strong>9. Publicity:</strong> Except where prohibited,
                  participation in the Promotion constitutes the winner's
                  consent to Main Sponsor’s and its agents’ use of winner’s
                  name, likeness, photograph, voice, opinions and/or hometown
                  and state for promotional purposes in any media, worldwide,
                  without further payment or consideration.
                </p>
                <p>
                  <strong>10. General Conditions:</strong> Main Sponsor reserves
                  the right to cancel, suspend and/or modify the Promotion, or
                  any part of it if any fraud, technical failures or any other
                  factor beyond Main Sponsor’s reasonable control impairs the
                  integrity or proper functioning of the Contest. Main Sponsor’s
                  failure to enforce any term of these Official Rules shall not
                  constitute a waiver of that provision.
                </p>
                <p>
                  <strong>11. Limitations of Liability:</strong> The Released
                  Parties are not responsible for: (1) any incorrect or
                  inaccurate information, whether caused by entrants, printing
                  errors or by any of the equipment or programming associated
                  with or utilized in the Promotion; (2) technical failures of
                  any kin including, but not limited to malfunctions,
                  interruptions, or disconnections in phone lines or network
                  hardware or software; (3) unauthorized human intervention in
                  any part of the entry process or the Promotion; (4) technical
                  or human error which may occur in the administration of the
                  Promotion or the processing of entries; or (5) any injury or
                  damage to persons or property which may be caused, directly or
                  indirectly, in whole or in part, from entrant’s participation
                  in the Promotion or receipt or use or misuse of any prize. If
                  for any reason an entrant’s entry is confirmed to have been
                  erroneously deleted, lost, or otherwise destroyed or
                  corrupted, entrant’s sole remedy is another entry in the
                  Giveaway, provided that it is not possible to award another
                  entry due to discontinuance of the Promotion, or any part of
                  it; for any reason, Main Sponsor, at its discretion, may elect
                  to hold a random drawing from among all eligible entries
                  received up to the date of discontinuance for any or all of
                  the prizes offered herein. No more than the stated number of
                  prizes will be awarded. In the event that production,
                  technical, seeding, programming or any other reasons cause
                  more than stated number of prizes as set forth in these
                  Official Rules to be available and/or claimed, Main Sponsor
                  reserves the right to award only the stated number of prizes
                  by a random drawing among all legitimate, un awarded, eligible
                  prize claims.
                </p>
                <p>
                  <strong>12. Disputes:</strong> Except where prohibited,
                  entrant agrees that: (1) any and all disputes, causes of
                  action arising out of or connected with this Promotion or any
                  prize awarded shall be resolved individually, without resort
                  to any form of class action, and exclusively by a state or
                  federal court located in California; (2) any and all to actual
                  out-of-pocket costs incurred, including costs associated with
                  entering this Promotion, but in no event attorneys’ fees; and
                  (3) under no circumstances will entrant be permitted to obtain
                  awards for, and entrant hereby waives all rights to claim,
                  indirect, punitive, incidental and consequential damages and
                  any other damages, other than for actual out-of-pocket
                  expenses, and any and all rights to have damages multiplied or
                  otherwise increased. All issues and questions concerning the
                  construction, validity, interpretation and enforceability of
                  these Official Rules, or the rights and obligations of the
                  entrant and Main Sponsor in connection with the Promotion,
                  shall be governed by, and construed in accordance with, the
                  laws of the State of California, without giving effect to any
                  choice of law or conflict of law rules (whether of the State
                  of California or any other jurisdiction), which would cause
                  the application of the laws of any jurisdiction other than the
                  State of California.
                </p>
                <p>
                  <strong>13. Entrant’s Personal Information:</strong>{' '}
                  Information collected from entrants is subject to the Main
                  Sponsor’s Privacy Policy.
                </p>
                <p>
                  <strong>14. Winners’ List:</strong> To receive a list of the
                  winners, send an email to:{' '}
                  <a href="mailto:hello@travelopod.com">hello@travelopod.com</a>{' '}
                  ATTN: Marketing, Domestic/International travel Giveaway 2023,
                  within thirty (30) days of expiration of the Giveaway Period.
                </p>
                <p>
                  <strong>15. Main Sponsor:</strong> Travelopod.com (Travelopod
                  Inc.)
                </p>
              </div>
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(WinTripTerms);
